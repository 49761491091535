import React, { useEffect, useState } from 'react'
import { useLangContext } from '../../i18n/ProvideLang';

const AdvertisingBanner2 = ({elem}) => {
    const {localizationsItems} = useLangContext();
    const [banner, setBanner] = useState();

    useEffect(() => {
        if(elem){
            setBanner(elem.elements[0])
        }
    }, [elem])

  return (
    banner &&
	<div className="section">
		<div className="container-fluid px-0">
			<div className="banner-center bg-cover" style={{backgroundImage: `url(${banner.image})`}}>
				<div className="banner-center-caption text-center">
					<div className="banner-center-text1">{banner.title}</div>
					<div className="banner-center-text2">{banner.description}</div>
					<div className="btn btn-white mt-5"><i className="icon-right-arrow"></i>
                        <span>{localizationsItems?.request_appointment}</span><i className="icon-right-arrow"></i>
                    </div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default AdvertisingBanner2