import React from 'react'
import Menu from './Menu'
import TopBar from '../simple/TopBar'

const Header = ({item}) => {
  return (
	<header className="header">
		<div className="header-quickLinks js-header-quickLinks d-lg-none">
			<div className="quickLinks-top js-quickLinks-top"></div>
			<div className="js-quickLinks-wrap-m"></div>
		</div>
        <TopBar item={item}/>
        <Menu item={item}/>
	</header>
  )
}

export default Header