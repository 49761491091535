import React, { useEffect } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';
import useFetchInfo from '../../hooks/useFetchInfo.hook'
import ServiceServices from '../../services/general/serviceServices';
import ServiceDetailContainer from '../../components/complex/ServiceDetailContainer';
import { Helmet } from 'react-helmet';
import ServiceSampleContainer from '../../components/complex/ServiceSampleContainer';

const ServicesDetail = () => {
    const {slug} = useParams();
    const {lang, localizationsItems} = useLangContext();
    const navigate = useNavigate();
    const {lang: lang2} = useParams();
  
    const {info, loading} = useFetchInfo(ServiceServices.view, slug, null, lang);
  
    useEffect(() => {
        if(lang){
            if(lang !== lang2) {
                navigate(`/${lang}/services/${slug}`)
            }
        }
    }, [lang])

  return (
    <>
        {loading ?
            <div className="loading-content">
                <div className="inner-circles-loader"></div>
            </div>
        :
        info && <div>
            <Helmet>
                <title>{info.seo_title ? info.seo_title : info.title}</title>
                {info.seo_description && <meta name="description" content={info.seo_description} />}
                {info.seo_keywords && <meta name="Keywords" content={info.seo_keywords}/>}
            </Helmet>
            <div className="section mt-0">
                <div className="breadcrumbs-wrap">
                    <div className="container">
                        <div className="breadcrumbs">
                            <NavLink to={`/${lang}`}>{localizationsItems?.home}</NavLink>
                            <NavLink to={`/${lang}/services`}>{localizationsItems?.services}</NavLink>
                            <span>{info.title}</span>
                        </div>
                    </div>
                </div>
            </div>
            <ServiceSampleContainer items={info}/>
        </div>}
    </>
  )
}

export default ServicesDetail