import ArticleDetail from "../pages/articles/ArticleDetail";
import ArticlesPage from "../pages/articles/ArticlesPage";
import MainPage from "../pages/main/MainPage";
import SamplePage from "../pages/sample/SamplePage";
import ServicesDetail from "../pages/services/ServicesDetail";
import ServicesPage from "../pages/services/ServicesPage";
import { ARTICLE_DETAIL_PAGE_ROUTE, ARTICLE_PAGE_ROUTE, MAIN_PAGE_ROUTE, SAMPLE_PAGE_SOUTE, SERVICES_DETAIL_PAGE_ROUTE, SERVICES_PAGE_ROUTE } from "./Constants";

export const publicRoutes = [
    {
        path: '/',
        Component: MainPage
    },
    {
        path: MAIN_PAGE_ROUTE,
        Component: MainPage
    },
    {
        path: SERVICES_PAGE_ROUTE,
        Component: ServicesPage
    },
    {
        path: SERVICES_DETAIL_PAGE_ROUTE,
        Component: ServicesDetail
    },
    {
        path: ARTICLE_PAGE_ROUTE,
        Component: ArticlesPage
    },
    {
        path: ARTICLE_DETAIL_PAGE_ROUTE,
        Component: ArticleDetail
    },
    {
        path: SAMPLE_PAGE_SOUTE,
        Component: SamplePage
    }
]