import React, { useRef } from 'react';
import CountUp from 'react-countup';
import useOnScreen from '../../hooks/useOnScreen.hook'
import Slider from 'react-slick';

const Advantages = ({elem}) => {
    const ref = useRef(null)
    const isVisible = useOnScreen(ref)

    const settings = {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        //autoplay: true,
        //autoplaySpeed: 6000,
        speed: 1200,
        arrows: false,
        dots: true,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1
                }
            }]
    }

  return (
    <div className="section">
        <div className="container" ref={ref}>
            <div className="title-wrap text-center">
                <div className="h-sub theme-color">{elem.subtitle}</div>
                <h2 className="h1">{elem.title}</h2>
                <div className="h-decor"></div>
            </div>
            <Slider className="row d-block js-counter-carousel" {...settings}>
                {elem.elements?.map((card, index) =>
                    <div className="col" key={index}>
                        <div className={isVisible ? "counter-box counted" : "counter-box"}>
                            <div className="counter-box-icon">
                                <img src={card.image} alt="" />
                            </div>
                            <div className="counter-box-number">
                                <span className="count">
                                    {isVisible ? <CountUp start={0} end={Number(card.counter)} duration={1.5}/> : <>00</>}
                                </span>{card.subtitle}
                            </div>
                            <div className="decor"></div>
                            <div className="counter-box-text">{card.title}</div>
                        </div>
                    </div>
                )}
            </Slider>
        </div>
    </div>
  )
}

export default Advantages