import React, { useState } from 'react'
import { useLangContext } from '../../i18n/ProvideLang';
import AppServices from '../../services/general/appServices';
import { useForm } from 'react-hook-form';
import Utils from '../../services/utils';

const SampleForm = ({elem}) => {
    const {localizationsItems} = useLangContext();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [success, setSuccess] = useState();

    const {handleSubmit, register, reset, control, formState: {errors}} = useForm();

    const CustomSubmit = async (data) => {
        setLoading(true);

        let newData = {
            fields: {}
        }

        for(var key in data){
            let temp = elem.elements.find(it => it.name === key)
            if(temp){
                newData.fields[temp.label] = data[key]
            }
        }

        let query = new URLSearchParams(window.location.search);

        if(query.toString()){
            query.get('utm_source') && (newData.utm_source = query.get('utm_source'));
            query.get('utm_medium') && (newData.utm_medium = query.get('utm_medium'))
            query.get('utm_campaign') && (newData.utm_campaign = query.get('utm_campaign'))
            query.get('utm_content') && (newData.utm_content = query.get('utm_content'))
            query.get('utm_term') && (newData.utm_term = query.get('utm_term'))
        }else{
            Utils.getCookie('utm_source') && newData.append('utm_source', Utils.getCookie('utm_source'));
            Utils.getCookie('utm_medium') && newData.append('utm_medium', Utils.getCookie('utm_medium'));
            Utils.getCookie('utm_campaign') && newData.append('utm_campaign', Utils.getCookie('utm_campaign'));
            Utils.getCookie('utm_content') && newData.append('utm_content', Utils.getCookie('utm_content'));
            Utils.getCookie('utm_term') && newData.append('utm_term', Utils.getCookie('utm_term'));
        }

        const res = await AppServices.requestTemplate(newData);

        if(res.statusCode === 200){
            setSuccess(true)
            setVisible(true)
            setTimeout(() => {
                setVisible(false)
                reset();
            }, 3000);
        }else{
            console.log(res)
            setSuccess(false)
        }
        setLoading(false)
    }

  return (
    <div className="section">
        <div className="container">
            <div className="row">
                <div className="col-md col-lg-5">
                    <div className="pr-0 pr-lg-8">
                        <div className="title-wrap">
                            <h2>{elem.title}</h2>
                            <div className="h-decor"></div>
                        </div>
                        <div className="mt-2 mt-lg-4">
                            <p dangerouslySetInnerHTML={{__html: elem.description}}></p>
                        </div>
                        <div className="mt-2 mt-md-5"></div>
                    </div>
                </div>
                <div className="col-md col-lg-6 mt-4 mt-md-0">
                    <form className="contact-form" onSubmit={handleSubmit(CustomSubmit)}>
                        {visible &&
                            <>
                                {success ?
                                    <div className="successform">
                                        <p>{localizationsItems?.form_success}</p>
                                    </div>
                                :
                                    <div className="errorform">
                                        <p>{localizationsItems?.form_fail}</p>
                                    </div>}
                            </>
                        }
                        {elem.elements?.map((card, index) =>
                            <div className="mt-15" key={index}>
                                <input type="text" className="form-control" {...register(card.name)} placeholder={card.placeholder}/>
                            </div>
                        )}
                        <div className="mt-3">
                            <button type="submit" className="btn btn-hover-fill"><i className="icon-right-arrow"></i><span>{localizationsItems?.send_form}</span><i className="icon-right-arrow"></i></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SampleForm