import React, { useEffect } from 'react'
import AppServices from '../../services/general/appServices';
import useFetchItems from '../../hooks/useFetchItems';
import { useLangContext } from '../../i18n/ProvideLang';
import { useNavigate, useParams } from 'react-router-dom';
import PageTop from '../../components/simple/PageTop';
import ServicesContainer from '../../components/complex/ServicesContainer';
import { Helmet } from 'react-helmet';
import About from '../../components/complex/About';
import Steps from '../../components/complex/Steps';
import AdvertisingBanner from '../../components/complex/AdvertisingBanner';
import Faq from '../../components/complex/Faq';
import MainServices from '../../components/complex/MainServices';
import Advantages from '../../components/complex/Advantages';
import Reviews from '../../components/complex/Reviews';
import Gallery from '../../components/complex/Gallery';
import SpecialOffers from '../../components/complex/SpecialOffers';
import SampleForm from '../../components/complex/SampleForm';

const ServicesPage = () => {
    const {lang} = useLangContext();
    const navigate = useNavigate();
    const {lang: lang2} = useParams();

    useEffect(() => {
        if(lang){
            if(lang !== lang2) {
                navigate(`/${lang}/services`)
            }
        }
    }, [lang])

    const {
        items,
        loading,
        setFilterData
    } = useFetchItems(AppServices.page, 'services')

  return (
    <>
        {loading ?
            <div className="loading-content">
                <div className="inner-circles-loader"></div>
            </div>
        :
        items && <>
            <Helmet>
                <title>{items.seo_title ? items.seo_title : items.title}</title>
                {items.seo_description && <meta name="description" content={items.seo_description} />}
                {items.seo_keywords && <meta name="Keywords" content={items.seo_keywords}/>}
            </Helmet>
            <div className="page-content">
                <PageTop title={items.title}/>
                <ServicesContainer item={items}/>
                {items.elements?.map((elem, index) => 
                <div key={index}>
                    {
                    elem.type === "about" ?
                        <About elem={elem}/>
                    :
                    elem.type === "steps" ?
                        <Steps elem={elem}/>
                    :
                    elem.type === "advertising-banner-2" ?
                        Number(elem.banner_type) === 2 ?
                            <AdvertisingBanner elem={elem}/>
                            :
                            <></>
                    :
                    elem.type === "faq" ?
                        <Faq elem={elem}/>
                    :
                    elem.type === "services-2" ?
                        <MainServices elem={elem}/>
                    :
                    elem.type === "advantages" ?
                        <Advantages elem={elem}/>
                    :
                    elem.type === "reviews" ?
                        <Reviews elem={elem}/>
                    :
                    elem.type === "gallery" ?
                        <Gallery elem={elem}/>
                    :
                    elem.type === "advantages-2" ?
                        <SpecialOffers elem={elem}/>
                    :
                    elem.type === "form" ?
                        <SampleForm elem={elem}/>
                    :
                        <></>
                    }
                </div>
            )}
            </div>
        </>}
    </>
  )
}

export default ServicesPage