import React from 'react'
import Fancybox from './FancyBox'

const Gallery = ({elem}) => {
  return (
    <div className="section">
        <div className="container">
            <div className="title-wrap text-center">
                {elem.subtitle && <div className="h-sub theme-color">{elem.subtitle}</div>}
                {elem.title && <h2 className="h1">{elem.title}</h2>}
                {elem.title && <div className="h-decor"></div>}
            </div>
            <p className="text-center max-900" dangerouslySetInnerHTML={{__html: elem.description}}></p>
            <div className="mt-2 mt-md-3 mt-lg-5"></div>
            <Fancybox className="project-area bg-secondary"
                options={{
                    Carousel: {
                    infinite: false,
                    },
                }}
            >
                <div className="sm-gallery-row">
                    {elem.elements?.map((image, index) =>
                        <a key={index} data-fancybox="gallery" href={image}>
                            <img src={image} alt=""/>
                        </a>
                    )}
                </div>
            </Fancybox>
        </div>
    </div>
  )
}

export default Gallery