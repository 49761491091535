import React from 'react'
import Slider from 'react-slick'
import { useLangContext } from '../../i18n/ProvideLang'

const SpecialOffers = ({elem}) => {
    const {localizationsItems} = useLangContext();

    const settings = {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3500,
        arrows: false,
        dots: true,
        speed: 1500,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1
                }
        }] 
    }
  return (
    <div className="section" id="specialOffer">
        <div className="container">
            <div className="title-wrap text-center">
                <div className="h-sub theme-color">{elem.subtitle}</div>
                <h2 className="h1">{elem.title}</h2>
                <div className="h-decor"></div>
                <div dangerouslySetInnerHTML={{__html: elem.description}}></div>
            </div>
            {elem.elements && 
            <Slider {...settings} className="special-carousel js-special-carousel row">
                {elem.elements.map((card, index) =>
                    <div className="col-12" key={index}>
                        <div className="special-card">
                            <div className="special-card-photo">
                                <img src={card.image} alt=""/>
                            </div>
                            <div className="special-card-caption text-left">
                                <div className="special-card-txt1">{card.title}</div>
                                <div className="special-card-txt2">{card.counter}</div>
                                <div className="special-card-txt3">{card.subtitle}</div>
                                {card.link &&<div><a href={card.link} className="btn"><i className="icon-right-arrow"></i><span>{localizationsItems?.more}</span><i className="icon-right-arrow"></i></a></div>}
                            </div>
                        </div>
                    </div>
                )}
            </Slider>}
        </div>
    </div>
  )
}

export default SpecialOffers