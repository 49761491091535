import React from 'react'
import Slider from 'react-slick'

const Steps = ({elem}) => {
    const settings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 1200,
        arrows: false,
        dots: true,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2
                }
            }]
    }

  return (
    <div className="section">
        <div className="container">
            <div className="title-wrap text-center">
                <div className="h-sub theme-color">{elem.subtitle}</div>
                <h2 className="h1">{elem.title}</h2>
                <div className="h-decor"></div>
            </div>
            <Slider {...settings} className="row js-icn-carousel icn-carousel flex-column flex-sm-row text-center text-sm-left" data-slick='{"slidesToShow": 3, "responsive":[{"breakpoint": 1024,"settings":{"slidesToShow": 2}}]}'>
                {elem.elements?.map((card, index) =>
                    <div className="col-md" key={index}>
                        <div className="icn-text icn-text-wmax">
                            <div className="icn-text-circle">
                                <img src={card.image} alt="" />
                            </div>
                            <div>
                                <h5 className="icn-text-title">{card.title}</h5>
                                <p dangerouslySetInnerHTML={{__html: card.description}}></p>
                            </div>
                        </div>
                    </div>
                )}
            </Slider>
        </div>
    </div>
  )
}

export default Steps