import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const SearchHeader = () => {
    const [term, setTerm] = useState('');
    const navigate = useNavigate();

    const searchOn = (e) => {
        e.stopPropagation();
        e.preventDefault();
        term ? navigate(`/ru/services?term=${term}`) : navigate(`/ru/services`);
    }

  return (
    <div className="header-search">
        <form className="form-inline" onSubmit={e => searchOn(e)}>
            <i className="icon-search" onClick={e => searchOn(e)}></i>
            <input type="text" placeholder="Search" value={term} onChange={(e) => setTerm(e.target.value)}/>
            <button type="submit" onSubmit={e => searchOn(e)}><i className="icon-search"></i></button>
        </form>
    </div>
  )
}

export default SearchHeader