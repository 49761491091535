import React from 'react'
import { useLangContext } from '../../i18n/ProvideLang';

const Packages = ({elem}) => {
    const {lang, localizationsItems} = useLangContext();

  return (
    <div className="section page-content-first">
        <div className="container">
            <div className="text-center mb-2 mb-md-3 mb-lg-4">
                <div className="h-sub theme-color">{elem.subtitle}</div>
                <h1>{elem.title}</h1>
                <div className="h-decor"></div>
            </div>
        </div>
        <div className="container">
            <div className="text-center mb-3 mb-md-4 max-900">
                <p dangerouslySetInnerHTML={{__html: elem.description}}></p>
            </div>
            <div id="tab-content" className="tab-content mt-3 mt-md-4">
                <div className="tab-pane fade show active" role="tabpanel">
                    <div className="table-scroll">
                        <div className="table-wrap">
                            <table className="table price-table js-fixed-table">
                                <tr>
                                    <th className="fixed-side">{localizationsItems?.table_type_treatment}</th>
                                    <th>{localizationsItems?.table_unit}</th>
                                    <th>{localizationsItems?.table_price}</th>
                                    <th>{localizationsItems?.table_count}</th>
                                </tr>
                                {elem.elements?.map((el, index) =>
                                    <tr key={index}>
                                        <td className="fixed-side" dangerouslySetInnerHTML={{__html: el.description}}></td>
                                        <td>{el.subtitle}</td>
                                        <td>{el.price}</td>
                                        <td>{el.date}</td>
                                    </tr>
                                )}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <p className="mt-2 p-sm">{localizationsItems?.table_polite}</p>
        </div>
    </div>
  )
}

export default Packages