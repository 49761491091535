import React from 'react'
import Utils from '../../services/utils'
import useFetchItems from '../../hooks/useFetchItems';
import ArticleServices from '../../services/general/articleServices';
import ShareBlock from '../simple/ShareBlock';
import { useLangContext } from '../../i18n/ProvideLang';
import { NavLink } from 'react-router-dom';

const ArticleDetailContainer = ({item}) => {
    const {lang, localizationsItems} = useLangContext();

    const {
        items,
    } = useFetchItems(ArticleServices.categories)

  return (
    <div className="section page-content-first">
        <div className="container">
            <div className="row">
                <div className="col-lg-9 aside">
                    <div className="blog-post blog-post-single">
                        <div className="blog-post-info">
                            <div className="post-date">
                                {Utils.generateDate2(item.date)?.day}<span>{Utils.generateDate2(item.date)?.mon}</span>
                            </div>
                            <div>
                                <h2 className="post-title"><a href="blog-post-page.html">{item.title}</a></h2>
                                <ShareBlock/>
                            </div>
                        </div>
                        <div className="post-image">
                            <img src={item.image} alt=""/>
                        </div>
                        <div className="post-text" dangerouslySetInnerHTML={{__html: item.description}}></div>
                    </div>
                </div>
                {items && <div className="col-lg-3 aside-left mt-5 mt-lg-0">
                    <div className="side-block">
                        <h3 className="side-block-title">{localizationsItems?.categories}</h3>
                        <ul className="categories-list">
                            {items.map((card, index) =>
                                <li key={index}><NavLink to={`/${lang}/articles?categories[]=${card.name}`}>{card.name}</NavLink></li>
                            )}
                            <li><NavLink to={`/${lang}/articles`}>{localizationsItems?.all_categories}</NavLink></li>
                        </ul>
                    </div>
                </div>}
            </div>
        </div>
    </div>
  )
}

export default ArticleDetailContainer