import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useFetchItems from '../../hooks/useFetchItems';
import ServiceServices from '../../services/general/serviceServices';
import { useLangContext } from '../../i18n/ProvideLang';
import { NavLink } from 'react-router-dom';

const MainServices = ({elem}) => {
    const {lang, localizationsItems} = useLangContext();

    const {
        items,
    } = useFetchItems(ServiceServices.indexQuery, elem.ids)

    const [banner1, setBanner1] = useState();
    const [banner2, setBanner2] = useState();
    const [banner3, setBanner3] = useState();
    const [banner4, setBanner4] = useState();

    useEffect(() => {
        if(items?.items){
            items.items[1] && setBanner1([items.items[0], items.items[1]])
            items.items[2] && setBanner2(items.items[2])
            items.items[3] && setBanner3(items.items[3])
            items.items[5] && setBanner4([items.items[4], items.items[5]])
        }
    }, [items])

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 1600,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true
    };
    const settings2 = {
        dots: true,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 1600,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true
    };

  return (
    <div className="section">
        <div className="container-fluid px-0">
            <div className="title-wrap text-center">
                <div className="h-sub theme-color">{elem.subtitle}</div>
                <h2 className="h1">{elem.title}</h2>
                <div className="h-decor"></div>
            </div>
            <div className="row no-gutters services-box-wrap services-box-wrap-desktop">
                {banner1 &&
                <div className="col-4 order-1">
                    <div className="service-box-rotator js-service-box-rotator">
                        <Slider {...settings}>
                            {banner1.map((card, index) =>
                                <div className="service-box service-box-greybg service-box--hiddenbtn" key={index}>
                                    <div className="service-box-caption text-center" onClick={(e) => {e.stopPropagation(); console.log(card.title)}}>
                                        <div className="service-box-icon">
                                            <img src={card.icon} alt="" />
                                        </div>
                                        <div className="service-box-icon-bg">
                                            <img src={card.icon} alt="" />
                                        </div>
                                        <h3 className="service-box-title">{card.title}</h3>
                                        <p>{card.subtitle}</p>
                                        <div className="btn-wrap">
                                            <NavLink to={`/${lang}/services/${card.slug}`} className="btn"><i className="icon-right-arrow"></i><span>{localizationsItems?.services_more}</span><i className="icon-right-arrow"></i></NavLink>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Slider>
                    </div>
                </div>}
                {banner2 &&
                <div className="col-8 order-2">
                    <div className="service-box">
                        <div className="service-box-image">
                            <img src={banner2.detail_image} alt="" />
                        </div>
                        <div className="service-box-caption text-center w-50 ml-auto">
                            <h3 className="service-box-title">{banner2.title}</h3>
                            <p>{banner2.subtitle}</p>
                            <div className="btn-wrap">
                                <NavLink to={`/${lang}/services/${banner2.slug}`} className="btn"><i className="icon-right-arrow"></i><span>{localizationsItems?.services_more}</span><i className="icon-right-arrow"></i></NavLink>
                            </div>
                        </div>
                    </div>
                </div>}
                {banner3 &&
                <div className="col-8 order-3 order-lg-4 order-xl-3">
                    <div className="service-box">
                        <div className="service-box-image">
                            <img src={banner3.detail_image} alt="" />
                        </div>
                        <div className="service-box-caption text-center w-50 ml-auto">
                            <h3 className="service-box-title">{banner3.title}</h3>
                            <p>{banner3.subtitle}</p>
                            <div className="btn-wrap">
                                <NavLink to={`/${lang}/services/${banner3.slug}`} className="btn"><i className="icon-right-arrow"></i><span>{localizationsItems?.services_more}</span><i className="icon-right-arrow"></i></NavLink>
                            </div>
                        </div>
                    </div>
                </div>}
                {banner4 &&
                <div className="col-4 order-5">
                    <div className="service-box-rotator js-service-box-rotator">
                        <Slider {...settings}>
                            {banner4.map((card, index) =>
                                <div className="service-box service-box-greybg service-box--hiddenbtn" key={index}>
                                    <div className="service-box-caption text-center">
                                        <div className="service-box-icon">
                                            <img src={card.icon} alt="" />
                                        </div>
                                        <div className="service-box-icon-bg">
                                            <img src={card.icon} alt="" />
                                        </div>
                                        <h3 className="service-box-title">{card.title}</h3>
                                        <p>{card.subtitle}</p>
                                        <div className="btn-wrap">
                                            <NavLink to={`/${lang}/services/${card.slug}`} className="btn">
                                                <i className="icon-right-arrow"></i>
                                                <span>{localizationsItems?.services_more}</span><i className="icon-right-arrow"></i>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Slider>
                    </div>
                </div>}
            </div>

            {items?.items && <div className="row no-gutters services-box-wrap services-box-wrap-mobile">
                <div className="service-box-rotator js-service-box-rotator">
                    <Slider {...settings2}>
                        {items.items.map((card, index) =>
                            <div className="service-box service-box-greybg service-box--hiddenbtn" key={index}>
                                <div className="service-box-caption text-center">
                                    <div className="service-box-icon"><i className="icon-icon-whitening"></i></div>
                                    <div className="service-box-icon-bg"><i className="icon-icon-whitening"></i></div>
                                    <h3 className="service-box-title">{card.title}</h3>
                                    <p>{card.subtitle}</p>
                                    <div className="btn-wrap">
                                        <NavLink to={`/${lang}/services/${card.slug}`} className="btn"><i className="icon-right-arrow"></i><span>{localizationsItems?.services_more}</span><i className="icon-right-arrow"></i></NavLink>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Slider>
                </div>
            </div>}
        </div>
    </div>
  )
}

export default MainServices