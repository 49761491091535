import React from 'react'
import WorkerItem from '../simple/WorkerItem';

const Workers = ({elem}) => {
  return (
    <div className="section page-content-first">
        <div className="container">
            <div className="text-center mb-2  mb-md-3 mb-lg-4">
                {elem.subtitle && <div className="h-sub theme-color">{elem.subtitle}</div>}
                {elem.title && <h1>{elem.title}</h1>}
                {elem.title && <div className="h-decor"></div>}
            </div>
        </div>
        <div className="container">
            <div className="text-center mb-2 mb-md-3">
                <p dangerouslySetInnerHTML={{__html: elem.description}}></p>
            </div>
            <div className="row justify-content-center">
                {elem.elements?.map((card, index) =>
                    <WorkerItem card={card} key={index}/>
                )}
            </div>
        </div>
    </div>
  )
}

export default Workers