import React, { useEffect } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';
import useFetchInfo from '../../hooks/useFetchInfo.hook';
import ArticleServices from '../../services/general/articleServices';
import ArticleDetailContainer from '../../components/complex/ArticleDetailContainer';
import { Helmet } from 'react-helmet';
import About from '../../components/complex/About';
import Steps from '../../components/complex/Steps';
import AdvertisingBanner from '../../components/complex/AdvertisingBanner';
import Faq from '../../components/complex/Faq';
import MainServices from '../../components/complex/MainServices';
import Advantages from '../../components/complex/Advantages';
import Reviews from '../../components/complex/Reviews';
import Gallery from '../../components/complex/Gallery';
import SpecialOffers from '../../components/complex/SpecialOffers';
import SampleForm from '../../components/complex/SampleForm';

const ArticleDetail = () => {
    const {slug} = useParams();
    const {lang, localizationsItems} = useLangContext();
    const navigate = useNavigate();
    const {lang: lang2} = useParams();

    const {info, loading} = useFetchInfo(ArticleServices.view, slug, null, lang);

    useEffect(() => {
        if(lang){
            if(lang !== lang2) {
                navigate(`/${lang}/articles/${slug}`)
            }
        }
    }, [lang])
    
  return (
    <>
        {loading ?
            <div className="loading-content">
                <div className="inner-circles-loader"></div>
            </div>
        :
        <>
            {info && <Helmet>
                <title>{info.seo_title ? info.seo_title : info.title}</title>
                {info.seo_description && <meta name="description" content={info.seo_description} />}
                {info.seo_keywords && <meta name="Keywords" content={info.seo_keywords}/>}
            </Helmet>}
            {info && <div className='page-content'>
                <div className="section mt-0">
                    <div className="breadcrumbs-wrap">
                        <div className="container">
                            <div className="breadcrumbs">
                                <NavLink to={`/${lang}`}>{localizationsItems?.home}</NavLink>
                                <NavLink to={`/${lang}/articles`}>{localizationsItems?.articles}</NavLink>
                                <span>{info.title}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <ArticleDetailContainer item={info}/>
                {info.elements?.map((elem, index) => 
                    <div key={index}>
                        { 
                        elem.type === "about" ?
                            <About elem={elem}/>
                        :
                        elem.type === "steps" ?
                            <Steps elem={elem}/>
                        :
                        elem.type === "advertising-banner-2" ?
                            Number(elem.banner_type) === 2 ?
                                <AdvertisingBanner elem={elem}/>
                                :
                                <></>
                        :
                        elem.type === "faq" ?
                            <Faq elem={elem}/>
                        :
                        elem.type === "services-2" ?
                            <MainServices elem={elem}/>
                        :
                        elem.type === "advantages" ?
                            <Advantages elem={elem}/>
                        :
                        elem.type === "reviews" ?
                            <Reviews elem={elem}/>
                        :
                        elem.type === "gallery" ?
                            <Gallery elem={elem}/>
                        :
                        elem.type === "advantages-2" ?
                            <SpecialOffers elem={elem}/>
                        :
                        elem.type === "form" ?
                            <SampleForm elem={elem}/>
                        :
                            <></>
                        }
                    </div>
            )}
            </div>}
        </>}
    </>
  )
}

export default ArticleDetail