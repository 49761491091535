import React from 'react'

const TextComponent = ({elem}) => {
  return (
	<div className="section page-content-first text-component">
		<div className="container">
			<div className="text-center mb-2  mb-md-3 mb-lg-4">
				{elem.subtitle && <div className="h-sub theme-color">{elem.subtitle}</div>}
				{elem.title && <h1>{elem.title}</h1>}
				{elem.title && <div className="h-decor"></div>}
			</div>
		</div>
		<div className="container">
			<div className="row">
                <div dangerouslySetInnerHTML={{__html: elem.description}}></div>
            </div>
        </div>
    </div>
  )
}

export default TextComponent