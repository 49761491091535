import React, { useEffect } from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import ServiceServices from '../../services/general/serviceServices'
import { NavLink, useLocation } from 'react-router-dom'
import { useLangContext } from '../../i18n/ProvideLang'

const ServicesContainer = ({item}) => {
    const {lang} = useLangContext();
    let query = new URLSearchParams(useLocation().search);

    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(ServiceServices.index)

    useEffect(() => {
        if(query.get('term')){
            setFilterData(query.toString())
        }
    }, [query.get('term')])

  return (
    <div className="section page-content-first">
        <div className="container">
            <div className="text-center mb-2  mb-md-3 mb-lg-4">
                <div className="h-sub theme-color">{item.subtitle}</div>
                <h1>{item.title}</h1>
                <div className="h-decor"></div>
                <div className="text-center mt-4">
                    <p dangerouslySetInnerHTML={{__html: item.description}}></p>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="row col-equalH">
                {items?.items?.map((card, index) =>
                    <div className="col-md-6 col-lg-4" key={index}>
                        <div className="service-card">
                            <div className="service-card-photo">
                                <NavLink to={`/${lang}/services/${card.slug}`}>
                                    <img src={card.image} className="img-fluid" alt=""/>
                                </NavLink>
                            </div>
                            <h5 className="service-card-name"><NavLink to={`/${lang}/services/${card.slug}`}>{card.title}</NavLink></h5>
                            <div className="h-decor"></div>
                            <div dangerouslySetInnerHTML={{__html: card.description}}></div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    </div>
  )
}

export default ServicesContainer