import React from 'react'
import { NavLink } from 'react-router-dom'

const SampleAdvantages2 = ({elem}) => {
  return (
    <div className="section page-content-first">
        <div className="container">
            <div className="text-center mb-2  mb-md-3 mb-lg-4">
                <div className="h-sub theme-color">{elem.subtitle}</div>
                <h1>{elem.title}</h1>
                <div className="h-decor"></div>
                <div className="text-center mt-4">
                    <p dangerouslySetInnerHTML={{__html: elem.description}}></p>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="row col-equalH justify-content-center">
                {elem.elements?.map((card, index) =>
                    <div className="col-md-6 col-lg-6" key={index}>
                        <div className="service-card service-card2">
                            <div className="service-card-photo">
                                <img src={card.image} className="img-fluid" alt=""/>
                            </div>
                            <h5 className="service-card-name"><div >{card.title}</div></h5>
                            <div className="h-decor"></div>
                            <div dangerouslySetInnerHTML={{__html: card.subtitle}}></div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    </div>
  )
}

export default SampleAdvantages2