import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';
import AppServices from '../../services/general/appServices';
import useFetchItems from '../../hooks/useFetchItems';
import SearchHeader from '../simple/SearchHeader';
import MenuItem from '../simple/MenuItem';

const Menu = ({item}) => {
    const {lang} = useLangContext();
    const [logo, setLogo] = useState();
    const [sticky, setStiky] = useState();

    const [show, setShow] = useState(false);
    const [searchEnabled, setSearchEnabled] = useState(false);
    
    const {
        items,
    } = useFetchItems(AppServices.menu, 'main-menu')

    useEffect(() => {
        if(item){
            setLogo(item.find(it => it.type === "logo")?.value);
            setSearchEnabled(item.find(it => it.type === "search-enabled")?.value);
        }
    }, [item])

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if(scrolled > 110){
            setStiky(true)
        }else{
            setStiky(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible)
    }, [])

  return (
    <div className={sticky ? "header-content stuck" : "header-content"}>
        <div className="container">
            <div className="row align-items-lg-center">
                <button className={show ? "navbar-toggler" : "navbar-toggler collapsed"} onClick={() => setShow(!show)} data-toggle="collapse" data-target="#navbarNavDropdown">
                    <span className="icon-menu"></span>
                </button>
                <div className="col-lg-auto col-lg-2 d-flex align-items-lg-center">
                    <NavLink to={`/${lang}`} className="header-logo">
                        <img src={logo} alt="" className="img-fluid"/>
                    </NavLink>
                </div>
                <div className="col-lg ml-auto header-nav-wrap">
                    <div className="header-nav js-header-nav">
                        <nav className="navbar navbar-expand-lg btco-hover-menu">
                            <div className={show ? "collapse navbar-collapse justify-content-end show" : "collapse navbar-collapse justify-content-end"} id="navbarNavDropdown">
                                <ul className="navbar-nav">
                                    {items?.map((it, index) =>
                                        <MenuItem key={index} it={it} setMenu={setShow}/>
                                    )}
                                </ul>
                            </div>
                        </nav>
                    </div>
                    {searchEnabled && <SearchHeader/>}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Menu