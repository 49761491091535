import React, { useEffect } from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import ArticleServices from '../../services/general/articleServices'
import { NavLink, useLocation } from 'react-router-dom'
import { useLangContext } from '../../i18n/ProvideLang'
import Utils from '../../services/utils'
import Pagination from '../simple/Pagination'
import ShareBlock from '../simple/ShareBlock'

const ArticlesContainer = () => {
    const {lang, localizationsItems} = useLangContext();
    let query = new URLSearchParams(useLocation().search);

    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(ArticleServices.index)
    
    const {
        items:categories,
    } = useFetchItems(ArticleServices.categories)

    useEffect(() => {
        if(query.get('categories[]')){
            setFilterData(query.toString())
        }
    }, [query.get('categories[]')])

  return (
    <div className="section page-content-first">
        <div className="container">
            <div className="row">
                <div className="col-lg-9 aside">
                    <div className="blog-posts">
                        {items?.items?.map((card, index) =>
                            <div className="blog-post" key={index}>
                                <div className="blog-post-info">
                                    <div className="post-date">
                                        {Utils.generateDate2(card.date)?.day}
                                        <span>{Utils.generateDate2(card.date)?.mon}</span></div>
                                    <div>
                                        <h2 className="post-title"><NavLink to={`/${lang}/articles/${card.slug}`}>{card.title}</NavLink></h2>
                                        <ShareBlock/>
                                    </div>
                                </div>
                                <div className="post-image">
                                    <NavLink to={`/${lang}/articles/${card.slug}`}><img src={card.image} alt=""/></NavLink>
                                </div>
                                <div className="post-teaser">
                                    {card.description}
                                </div>
                                <div className="mt-3 mt-lg-4">
                                    <NavLink to={`/${lang}/articles/${card.slug}`} className="btn btn-sm btn-hover-fill"><i className="icon-right-arrow"></i>
                                        <span>{localizationsItems?.read_more}</span><i className="icon-right-arrow"></i>
                                    </NavLink>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="clearfix"></div>
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                </div>
                {categories && <div className="col-lg-3 aside-left mt-5 mt-lg-0">
                    <div className="side-block">
                        <h3 className="side-block-title">{localizationsItems?.categories}</h3>
                        <ul className="categories-list">
                            {categories.map((card, index) =>
                                <li key={index}><NavLink to={`/${lang}/articles?categories[]=${card.name}`}>{card.name}</NavLink></li>
                            )}
                            <li><NavLink to={`/${lang}/articles`} onClick={() => setFilterData('')}>{localizationsItems?.all_categories}</NavLink></li>
                        </ul>
                    </div>
                </div>}
            </div>
        </div>
    </div>
  )
}

export default ArticlesContainer