import React, { useEffect } from 'react'
import useFetchItems from '../../hooks/useFetchItems';
import AppServices from '../../services/general/appServices';
import { useNavigate, useParams } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';
import PageTop from '../../components/simple/PageTop';
import Steps from '../../components/complex/Steps';
import AdvertisingBanner from '../../components/complex/AdvertisingBanner';
import Faq from '../../components/complex/Faq';
import MainServices from '../../components/complex/MainServices';
import Advantages from '../../components/complex/Advantages';
import Reviews from '../../components/complex/Reviews';
import Gallery from '../../components/complex/Gallery';
import About2 from '../../components/complex/About2';
import SampleAdvantages from '../../components/complex/SampleAdvantages';
import AdvertisingBanner2 from '../../components/complex/AdvertisingBanner2';
import Workers from '../../components/complex/Workers';
import Packages from '../../components/complex/Packages';
import SampleForm from '../../components/complex/SampleForm';
import { Helmet } from 'react-helmet';
import MainBanner from '../../components/complex/MainBanner';
import About from '../../components/complex/About';
import TextComponent from '../../components/complex/TextComponent';
import SpecialOffers from '../../components/complex/SpecialOffers';
import SampleAdvantages2 from '../../components/complex/SampleAdvantages2';

const SamplePage = () => {
    const {slug} = useParams();
    const {lang} = useLangContext();
    const navigate = useNavigate();
    const{lang: lang2} = useParams();

    useEffect(() => {
        if(lang){
            if(lang !== lang2){
                navigate(`/${lang}/page/${slug}`)
            }
        }
    }, [lang])
    
    const {
        items,
        loading,
    } = useFetchItems(AppServices.page, slug)

  return (
    <>
        {loading ?
        <div className="loading-content">
            <div className="inner-circles-loader"></div>
        </div>
            :
        <div className="page-content">
            <Helmet>
                <title>{items.seo_title ? items.seo_title : items.title}</title>
                {items.seo_description && <meta name="description" content={items.seo_description} />}
                {items.seo_keywords && <meta name="Keywords" content={items.seo_keywords}/>}
            </Helmet>
            <PageTop title={items.title}/>
            {items.description && <div dangerouslySetInnerHTML={{__html:items.description}}></div>}
            {items?.elements?.map((elem, index) => 
                <div key={index}>
                    {      
                    elem.type === "opening-slider" ?
                        <MainBanner elem={elem}/>
                    :          
                    elem.type === "about-2" ?
                        <About2 elem={elem}/>
                    :
                    elem.type === "about" ?
                        <About elem={elem}/>
                    :
                    elem.type === "steps" ?
                        <Steps elem={elem}/>
                    :
                    elem.type === "advertising-banner-2" ?
                        Number(elem.banner_type) === 2 ?
                            <AdvertisingBanner elem={elem}/>
                            :
                        Number(elem.banner_type) === 3 ?
                            <AdvertisingBanner2 elem={elem}/>
                            :
                            <></>
                    :
                    elem.type === "faq" ?
                        <Faq elem={elem}/>
                    :
                    elem.type === "advantages-2" ?
                        elem.is_component ?
                            <SampleAdvantages2 elem={elem}/>
                            :
                            <SpecialOffers elem={elem}/>
                    :
                    elem.type === "advantages" ?
                        elem.is_component ?
                            <SampleAdvantages elem={elem}/>
                            :
                            <Advantages elem={elem}/>
                    :
                    elem.type === "reviews" ?
                        <Reviews elem={elem}/>
                    :
                    elem.type === "gallery" ?
                        <Gallery elem={elem}/>
                    :
                    elem.type === "workers" ?
                        <Workers elem={elem}/>
                    :
                    elem.type === "packages" ?
                        <Packages elem={elem}/>
                    :
                    elem.type === "form" ?
                        <SampleForm elem={elem}/>
                    :
                    elem.type === "text-information" ?
                        <TextComponent elem={elem}/>
                    :
                        <></>
                    }
                </div>
            )}
        </div>
        }
    </>
    
  )
}

export default SamplePage