import React from 'react'
import { useState } from 'react'
import { NavLink } from 'react-router-dom';

const MenuItem = ({it, setMenu}) => {
    const [show, setShow] = useState();

  return (
    <li className="nav-item">
        {it.items ? 
            <div className="nav-link dropdown-toggle" data-toggle="dropdown" target={it.type} onClick={() => setShow(!show)}>{it.title}</div>
        :
            <NavLink to={it.value} className="nav-link" onClick={() => setMenu(false)} data-toggle="dropdown" target={it.type}>{it.title}</NavLink>
        }
        {it.items && 
            <ul className={show ? "dropdown-menu show" : "dropdown-menu"}>
                {it.items.map((el, index) =>
                    <li key={index}><NavLink className="dropdown-item" to={el.value} onClick={() => setMenu(false)} target={el.type}>{el.title}</NavLink></li>
                )}
            </ul>
        }
    </li>
  )
}

export default MenuItem