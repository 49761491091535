import React, { useState } from 'react'
import { Accordion } from 'react-bootstrap'
import FaqItem from '../simple/FaqItem'
import { useLangContext } from '../../i18n/ProvideLang';
import QuestionModal from '../modals/QuestionModal';

const Faq = ({elem}) => {
    const {localizationsItems} = useLangContext();
    const [show, setShow] = useState(false);

  return (
    <div className="section bg-grey py-0">
        <div className="container-fluid px-0">
            <div className="row no-gutters">
                <div className="col-xl-6 banner-left bg-fullheight" style={{backgroundImage: `url(${elem.image})`}}></div>
                <div className="col-xl-6">
                    <div className="faq-wrap">
                        <div className="title-wrap">
                            <h2 className="h1" dangerouslySetInnerHTML={{__html: elem.title}}></h2>
                        </div>
                        <div className="nav nav-pills"></div>
                        <div className="tab-content mt-sm-1">
                            <div className="tab-pane fade show active">
                                <Accordion defaultActiveKey={`collapseOne0`}>
                                    {elem.elements?.map((card, index) =>
                                        <FaqItem card={card} key={index} index={index}/>
                                    )}
                                </Accordion>
                            </div>
                        </div>
                        <button className="btn mt-15 mt-md-3" onClick={() => setShow(true)}><i className="icon-right-arrow"></i><span>{localizationsItems?.ask_question}</span><i className="icon-right-arrow"></i></button>
                    </div>
                </div>
            </div>
        </div>
        {show && <QuestionModal show={show} setShow={setShow}/>}
    </div>
  )
}

export default Faq