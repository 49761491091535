import React, { useState } from 'react'
import { useLangContext } from '../../i18n/ProvideLang';
import ReceptionModal from '../modals/ReceptionModal';

const WorkerItem = ({card}) => {
    const {localizationsItems} = useLangContext();
    const [show, setShow] = useState(false);

  return (
    <>
        <div className="col-md-6 col-lg-4">
            <div className="doctor-box text-center">
                <div className="doctor-box-photo">
                    <a href="doctor-page.html">
                        <img src={card.image} className="img-fluid" alt=""/>
                    </a>
                </div>
                <h5 className="doctor-box-name">
                    <a href="doctor-page.html">{card.name}</a>
                </h5>
                <div className="doctor-box-position">{card.position}</div>
                <div className="doctor-box-text">
                    <p dangerouslySetInnerHTML={{__html: card.description}}></p>
                </div>
                <div className="doctor-box-bottom">
                    <button className="btn" onClick={() => setShow(true)}>
                        <i className="icon-right-arrow"></i><span>{localizationsItems?.making_appointment}</span><i className="icon-right-arrow"></i>
                    </button>
                </div>
            </div>
        </div>
        {show && <ReceptionModal show={show} setShow={setShow}/>}
    </>
  )
}

export default WorkerItem