import React from 'react'
import {
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    TelegramIcon,
    TwitterIcon,
    FacebookIcon,
    WhatsappIcon
} from "react-share";

const ShareBlock = () => {
    const shareUrl = window.location.href;
    
  return (
    <div className="post-meta">
        <div className="post-meta-social">
            <FacebookShareButton url={shareUrl}><FacebookIcon size={30} round={true}></FacebookIcon></FacebookShareButton>
            <TwitterShareButton url={shareUrl}><TwitterIcon size={30} round={true}></TwitterIcon></TwitterShareButton>
            <WhatsappShareButton url={shareUrl}><WhatsappIcon size={30} round={true}></WhatsappIcon></WhatsappShareButton>
            <TelegramShareButton url={shareUrl}><TelegramIcon size={30} round={true}></TelegramIcon></TelegramShareButton>
        </div>
    </div>
  )
}

export default ShareBlock