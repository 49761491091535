import React, { useState } from 'react'
import Slider from 'react-slick'
import { NavLink } from 'react-router-dom';
import ReceptionModal from '../modals/ReceptionModal';

const MainBanner = ({elem}) => {
    const [show, setShow] = useState(false);

    const settings = {
        autoplay: true,
        autoplaySpeed: 6000,
        cssEase: 'ease-in-out',
        fade: true,
        speed: 2000,
        pauseOnHover: false,
        arrows: false
    }

  return (
    <div className="section mt-0">
        <div id="mainSliderWrapper">
            {elem &&
                <Slider {...settings} className="main-slider arrows-white arrows-bottom">
                    {elem.elements.map((card, index) =>
                        <div className="slide" key={index}>
                            <div className="img-holder" data-animation="kenburns">
                                <img src={card.front_image} alt="" />
                            </div>
                            <div className="slide-content center" style={{color: card.banner_font_color ? card.banner_font_color : '#fff'}}>
                                <div className="vert-wrap container">
                                    <div className="vert">
                                        <div className="container">
                                            <div className="slide-txt1" data-animation="fadeInDown" data-animation-delay="1s" dangerouslySetInnerHTML={{__html: card.title}}></div>
                                            <div className="slide-txt2" data-animation="fadeInUp" data-animation-delay="1.5s" dangerouslySetInnerHTML={{__html: card.description}}></div>
                                            {card.link ? <div className="slide-btn">
                                                <NavLink to={card.link} className="btn btn-white" data-animation="fadeInUp" data-animation-delay="2s"><i className="icon-right-arrow"></i>
                                                    <span>{card.button_text}</span><i className="icon-right-arrow"></i>
                                                </NavLink>
                                            </div>
                                            :
                                            card.button_text &&
                                                <div className="slide-btn" onClick={() => setShow(true)}>
                                                    <div className="btn btn-white" data-animation="fadeInUp" data-animation-delay="2s"><i className="icon-right-arrow"></i>
                                                        <span>{card.button_text}</span><i className="icon-right-arrow"></i>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Slider>
            }
        </div>
        {show && <ReceptionModal show={show} setShow={setShow}/>}
    </div>
  )
}

export default MainBanner