import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import useFetchItems from '../../hooks/useFetchItems';
import ArticleServices from '../../services/general/articleServices';
import { useLangContext } from '../../i18n/ProvideLang';
import { useForm } from 'react-hook-form';
import Utils from '../../services/utils';
import AppServices from '../../services/general/appServices';

const Footer = ({item}) => {
    const {localizationsItems} = useLangContext();
    const [socials, setSocials] = useState();
    const [logo, setLogo] = useState();
    const [text, setText] = useState();
    const [email, setEmail] = useState();
    const [address, setAddress] = useState();
    const [phone, setPhone] = useState();
    const [workTime, setWorkTime] = useState();

    useEffect(() => {
        if(item){
            setSocials(item.find(it => it.type === "socials")?.value);
            setLogo(item.find(it => it.type === "logo-white")?.value);
            setText(item.find(it => it.type === "footer-text")?.value);
            setEmail(item.find(it => it.type === "email")?.value);
            setAddress(item.find(it => it.type === "address")?.value);
            setPhone(item.find(it => it.type === "phone")?.value);
            setWorkTime(item.find(it => it.type === "work_time")?.value);
        }
    }, [item])

    const {
        items,
    } = useFetchItems(ArticleServices.index)

    const {
        items: menus,
    } = useFetchItems(AppServices.menu, 'bottom-menu')

  return (
	<div className="footer">
		<div className="container">
			<div className="row py-1 py-md-2 px-lg-0">
				<div className="col-lg-4 footer-col1">
					<div className="row flex-column flex-md-row flex-lg-column">
						<div className="col-md col-lg-auto footer-center">
							<div className="footer-logo">
								<img src={logo} alt="" className="img-fluid"/>
							</div>
						</div>
						<div>
							<div className="footer-text mt-1 mt-lg-2">
                                {text?.map((tx, index) =>
                                    <p key={index}>{tx}</p>
                                )}
							</div>
                            {socials && 
							<div className="footer-social d-md-none d-lg-block">
                                    {socials.map((sc, index) =>
                                    sc.value &&
                                        sc.type === "tiktok" ?
                                            <NavLink key={index} to={sc.value} target='_blank'>
                                                <svg width="18" height="18" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M34.1451 0H26.0556V32.6956C26.0556 36.5913 22.9444 39.7913 19.0725 39.7913C15.2007 39.7913 12.0894 36.5913 12.0894 32.6956C12.0894 28.8696 15.1315 25.7391 18.8651 25.6V17.3913C10.6374 17.5304 4 24.2783 4 32.6956C4 41.1827 10.7757 48 19.1417 48C27.5075 48 34.2833 41.1131 34.2833 32.6956V15.9304C37.3255 18.1565 41.059 19.4783 45 19.5479V11.3391C38.9157 11.1304 34.1451 6.12173 34.1451 0Z" fill="#fff"/>
                                                </svg>
                                            </NavLink>
                                        :
                                        sc.type === "telegram" ?
                                            <NavLink key={index} to={sc.value} target='_blank'>
                                                <svg width="18" height="18" viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M1.79176 9.29865C8.78825 6.25039 13.4537 4.2408 15.788 3.26986C22.4531 0.497636 23.838 0.0160711 24.7407 0.000169546C24.9392 -0.00332783 25.3832 0.0458755 25.6707 0.279201C25.9135 0.476217 25.9803 0.742358 26.0123 0.92915C26.0443 1.11594 26.0841 1.54146 26.0524 1.87395C25.6912 5.66891 24.1284 14.8783 23.3333 19.1287C22.9969 20.9272 22.3345 21.5303 21.6932 21.5893C20.2994 21.7175 19.2411 20.6682 17.8912 19.7833C15.7789 18.3987 14.5856 17.5367 12.5352 16.1856C10.1657 14.6241 11.7017 13.7659 13.0521 12.3633C13.4055 11.9962 19.5463 6.4107 19.6652 5.90401C19.68 5.84064 19.6938 5.60442 19.5535 5.47969C19.4132 5.35496 19.2061 5.39762 19.0566 5.43154C18.8447 5.47962 15.4703 7.71 8.93326 12.1227C7.97543 12.7804 7.10787 13.1009 6.33055 13.0841C5.47363 13.0656 3.82525 12.5995 2.59985 12.2012C1.09685 11.7127 -0.0977048 11.4543 0.00631425 10.6246C0.0604938 10.1924 0.655644 9.75044 1.79176 9.29865Z" fill="white"/>
                                                </svg>
                                            </NavLink>
                                        :
                                        <NavLink to={sc.value} target="_blank" className="hovicon" key={index}>
                                            <i className={
                                                sc.type === "twitter" ? "icon-twitter-logo"
                                                :
                                                sc.type === "facebook" ? "icon-facebook-logo"
                                                :
                                                sc.type === "google" ? "icon-google-logo"
                                                :
                                                sc.type === "instagram" ? "icon-instagram"
                                                :
                                                "icon-facebook-logo"
                                            }></i>
                                        </NavLink>
                                    )}
							</div>}
						</div>
					</div>
				</div>
                {items?.items?.length >= 1 && 
                    <div className="col-sm-6 col-lg-4">
                        <h3>{localizationsItems?.footer_posts}</h3>
                        <div className="h-decor"></div>
                        {items.items.slice(0, 3).map((card, index) =>
                            <div className="footer-post d-flex" key={index}>
                                <div className="footer-post-photo"><img src={card.image} alt="" className="img-fluid"/></div>
                                <div className="footer-post-text">
                                    <div className="footer-post-title"><a href="post.html">{card.title}</a></div>
                                    <p>{card.date}</p>
                                </div>
                            </div>
                        )}
                    </div>
                }
                {menus?.map((el, index) => 
                    <div className="col-sm-6 col-lg-4" key={index}>
                        <h3>{el.title}</h3>
                        <div className="h-decor"></div>
                        <ul className="menu-list">
                            {el.items?.map((it, index) =>
                                <li key={index}>
                                    <NavLink to={it.value} target={it.type}>{it.title}</NavLink>
                                </li>
                            )}
                        </ul>
                    </div>
                )}
				<div className="col-sm-6 col-lg-4">
					<h3>{localizationsItems?.contacts}</h3>
					<div className="h-decor"></div>
					<ul className="icn-list">
                        {address &&
						<li><i className="icon-placeholder2"></i>
                            {address.map((ad, index) =>
                                <span key={index}>
                                    {ad}
                                </span>
                            )}
						</li>}
                        {phone &&
                            <li><i className="icon-telephone"></i><b>
                                <span className="phone">
                                    {phone.map((ph, index) =>
                                        <a href={`tel:${ph}`} className="text-nowrap" key={index}>{ph}</a>,
                                    )}
                                </span></b>
                                {workTime && 
                                    <>
                                        <br/>(
                                        {workTime.map((wt, index) =>
                                            <span key={index}>{wt}</span>
                                        )})
                                    </>
                                }
                                    
                            </li>
                        }
                        {email &&
                            <li><i className="icon-black-envelope"></i>
                                {email.map((em, index) =>
                                    <a href={`mailto:${em}`} key={index}>{em}</a>
                                )}
                            </li>
                        }
					</ul>
				</div>
			</div>
		</div>
		<div className="footer-bottom">
			<div className="container">
				<div className="row text-center text-md-left">
					<div className="col-sm">© ТОО Canvas, 2012</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default Footer