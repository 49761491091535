import React, { useEffect, useState } from 'react'

const About2 = ({elem}) => {
    const [img1, setImg1] = useState();
    const [img2, setImg2] = useState();

    useEffect(() => {
        if(elem){
            setImg1(elem.elements?.find(it => it.type === "images")?.elements[0])
            setImg2([elem.elements?.find(it => it.type === "images")?.elements[1], elem.elements?.find(it => it.type === "images")?.elements[2]])
        }
    }, [elem])

  return (
	<div className="section page-content-first">
		<div className="container">
			<div className="text-center mb-2  mb-md-3 mb-lg-4">
				<div className="h-sub theme-color">{elem.subtitle}</div>
				<h1>{elem.title}</h1>
				<div className="h-decor"></div>
			</div>
		</div>
		<div className="container">
			<div className="row">
				<div className="col-lg-6 text-center text-lg-left pr-md-4">
					{img1 && <img src={img1} className="w-100" alt=""/>}
					{img2 && <div className="row mt-3">
                        {img2.map((image, index) => 
                        	<div className="col-6" key={index}>
                                <img src={image} className="w-100" alt=""/>
                            </div>
                        )}
					</div>}
				</div>
				<div className="col-lg-6 mt-3 mt-lg-0"
                dangerouslySetInnerHTML={{__html: elem.description}}>
				</div>
			</div>
		</div>
	</div>
  )
}

export default About2