import React from 'react'
import Slider from 'react-slick'

const Reviews = ({elem}) => {
    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: true,
        dots: false,
        speed: 1200,
    };

  return (
    <div className="section bg-grey py-0">
        <div className="container-fluid px-0">
            <div className="row no-gutters">
                <div className="col-sm-8 col-lg-6 mt-2 mt-lg-0 order-1 order-sm-0">
                    <div className="reviews-wrap ml-auto d-flex flex-column justify-content-center">
                        <div className="title-wrap text-center text-md-right">
                            <div className="h-sub">{elem.subtitle}</div>
                            <h2 className="h1" dangerouslySetInnerHTML={{__html: elem.title}}></h2>
                        </div>
                        {elem.elements &&
                        <Slider className="js-reviews-carousel reviews-carousel text-center text-md-right" {...settings}>
                            {elem.elements.map((card, index) =>
                                <div className="review" key={index}>
                                    <p className="review-text" dangerouslySetInnerHTML={{__html: card.description}}></p>
                                    <p><span className="review-author">- {card.title},</span> <span className="review-author-position">{card.subtitle}</span></p>
                                </div>
                            )}
                        </Slider>}
                    </div>
                </div>
                <div className="col-sm-4 col-lg-6 order-0 order-sm-1 reviews-photo">
                    <img src={elem.review_image} alt=""/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Reviews