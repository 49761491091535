import React, { useEffect, useState } from 'react'
import { useLangContext } from '../../i18n/ProvideLang';
import ReceptionModal from '../modals/ReceptionModal';

const About = ({elem}) => {
    const {localizationsItems} = useLangContext();
    const [image, setImage] = useState();
    const [show, setShow] = useState(false);

    useEffect(() => {
        if(elem){
            setImage(elem.elements?.find(it => it.type === "images")?.elements[0])
        }
    }, [elem])
  return (
    <div className="section">
        <div className="container pt-lg-2">
            <div className="title-wrap text-center text-md-left d-lg-none mb-lg-2">
                <div className="h-sub">{elem.subtitle}</div>
                <h2 className="h1" dangerouslySetInnerHTML={{__html: elem.title}}></h2>
            </div>
            <div className="row mt-2 mt-md-3 mt-lg-0">
                <div className="col-md-6">
                    <div className="title-wrap hidden d-none d-lg-block text-center text-md-left">
                        <div className="h-sub">{elem.subtitle}</div>
                        <h2 className="h1" dangerouslySetInnerHTML={{__html: elem.title}}></h2>
                    </div>
                    <div dangerouslySetInnerHTML={{__html: elem.description}}>
                    </div>
                    <div className="mt-2 mt-md-4"></div>
                    <div className="btn-link" onClick={() => setShow(true)}>{localizationsItems?.booking_visit}<i className="icon-right-arrow"></i></div>
                </div>
                <div className="col-md-6 mt-3 mt-md-0">
                    <div className="video-wrap embed-responsive embed-responsive-16by9">
                        <img src={image} alt="" />
                    </div>
                </div>
            </div>
        </div>
        {show && <ReceptionModal show={show} setShow={setShow}/>}
    </div>
  )
}

export default About