import React from 'react'
import { NavLink } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';

const PageTop = ({title}) => {
    const {lang, localizationsItems} = useLangContext();

  return (
    <div className="section mt-0">
        <div className="breadcrumbs-wrap">
            <div className="container">
                <div className="breadcrumbs">
                    <NavLink to={`/${lang}`}>{localizationsItems?.home}</NavLink>
                    <span>{title}</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PageTop