import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Utils from '../../services/utils';
import AppServices from '../../services/general/appServices';
import { useLangContext } from '../../i18n/ProvideLang';

const ReceptionModal = ({show, setShow}) => {
    const {localizationsItems} = useLangContext();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [success, setSuccess] = useState();

    const handleClose = () => setShow(false);

    const {handleSubmit, register, reset, control, formState: {errors}} = useForm();

    const CustomSubmit = async (data) => {
        setLoading(true);

        let newData = {
            fields: {
                ...data
            }
        }

        let query = new URLSearchParams(window.location.search);

        if(query.toString()){
            query.get('utm_source') && (newData.utm_source = query.get('utm_source'));
            query.get('utm_medium') && (newData.utm_medium = query.get('utm_medium'))
            query.get('utm_campaign') && (newData.utm_campaign = query.get('utm_campaign'))
            query.get('utm_content') && (newData.utm_content = query.get('utm_content'))
            query.get('utm_term') && (newData.utm_term = query.get('utm_term'))
        }else{
            Utils.getCookie('utm_source') && newData.append('utm_source', Utils.getCookie('utm_source'));
            Utils.getCookie('utm_medium') && newData.append('utm_medium', Utils.getCookie('utm_medium'));
            Utils.getCookie('utm_campaign') && newData.append('utm_campaign', Utils.getCookie('utm_campaign'));
            Utils.getCookie('utm_content') && newData.append('utm_content', Utils.getCookie('utm_content'));
            Utils.getCookie('utm_term') && newData.append('utm_term', Utils.getCookie('utm_term'));
        }

        const res = await AppServices.requestTemplate(newData);

        if(res.statusCode === 200){
            setSuccess(true)
            setVisible(true)
            setTimeout(() => {
                setVisible(false)
                reset();
            }, 3000);
        }else{
            console.log(res)
            setSuccess(false)
        }
        setLoading(false)
    }

  return (
    <Modal show={show} onHide={handleClose} centered className="modal-form">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
			<h3>{localizationsItems?.book_appointment}</h3>
			<form className="mt-15" onSubmit={handleSubmit(CustomSubmit)}>
                {visible &&
                    <>{success ?
						<div className="successform">
							<p>{localizationsItems?.form_success}</p>
						</div>
                        :
						<div className="errorform">
							<p>{localizationsItems?.form_fail}</p>
						</div>
                        }
                    </>
                }
				<div className="input-group">
					<span>
						<i className="icon-user"></i>
					</span>
					<input type="text" {...register('name')} className="form-control" placeholder={localizationsItems?.name} />
				</div>
				<div className="row row-xs-space mt-1">
					<div className="col-sm-6">
						<div className="input-group">
							<span>
								<i className="icon-email2"></i>
							</span>
							<input type="text" {...register('email')} className="form-control" placeholder={localizationsItems?.email} />
						</div>
					</div>
					<div className="col-sm-6 mt-1 mt-sm-0">
						<div className="input-group">
							<span>
								<i className="icon-smartphone"></i>
							</span>
							<input type="text" {...register('phone')} className="form-control" placeholder={localizationsItems?.phone} />
						</div>
					</div>
				</div>
					<div className="input-group">
						<span>
							<i className="icon-birthday"></i>
						</span>
						<input type="text" {...register('age')} className="form-control" placeholder={localizationsItems?.your_age} />
					</div>
					<div className="input-group flex-nowrap mt-1">
						<span>
							<i className="icon-calendar2"></i>
						</span>
						<div className="datepicker-wrap">
							<input name="bookingdate" type="date" {...register('date')} className="form-control datetimepicker" placeholder={localizationsItems?.date}/>
						</div>
					</div>
					<div className="input-group flex-nowrap mt-1">
						<span>
							<i className="icon-clock"></i>
						</span>
						<div className="datepicker-wrap">
							<input name="bookingtime" type="time" {...register('time')} className="form-control timepicker" placeholder={localizationsItems?.time}/>
						</div>
					</div>
					<textarea name="bookingmessage" className="form-control" placeholder={localizationsItems?.message}></textarea>
					<div className="text-right mt-2">
						<button type="submit" className="btn btn-sm btn-hover-fill">{localizationsItems?.book}</button>
					</div>
			</form>
        </Modal.Body>
    </Modal>
  )
}

export default ReceptionModal